import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Planet from '../Assets/planet.svg';
import MyBtn from '../ui/MyBtn/MyBtn';
import './Home.css';
import MyInput from "../ui/MyInput/MyInput";
import {useDispatch} from "react-redux";
import {sendContactFormThunk} from "../../store/reducers/actions";
import CustomModal from "../ui/customModal/customModal";

const Home = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(sendContactFormThunk(formData));
            setIsPopupOpen(true);
        } catch (error) {
            alert("Произошла ошибка. Пожалуйста, попробуйте еще раз.");
        }
    };

    return (
        <section className='section'>
                <div className="home-container container grid ">
                    <div className="primary-home-content grid">
                        <div className="home-details">
                            <h1 className="section-title">
                                БАНК ДЛЯ МЕНЯЮЩЕГОСЯ МИРА
                            </h1>
                            <p className="home-description">
                                Банк способствует экономическому и социальному развитию резидентов
                                славянских стран на международной арене. Мы содействуем становлению
                                и развитию бизнеса своих клиентов путем предоставления широкого спектра
                                банковских услуг высокого качества и использования передовых банковских
                                технологий.
                            </p>
                            <div className="btn-block">
                                <MyBtn
                                    title="Подробнее" large
                                    onClick={() => navigate("/aboutBank")}
                                />
                            </div>
                        </div>

                        <div className="home-img-container">
                            <img src={Planet} alt="Planet" className='home-img'/>
                        </div>
                    </div>

                    <div className="quote-container">
                        <p className="quote-text">“Международная СберКасса - это способ уверенного маневрирования
                            Вашего Бизнеса в сторону успеха.”
                        </p>
                    </div>

                    <div className="text-section">
                        <div className="text-block">
                            <div className="section-title">Банк сегодня</div>
                            <div className="finance-content">
                                Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем
                                индивидуальных и корпоративных клиентов различных форм собственности и направлений
                                деятельности.
                            </div>
                            <div className="btn-block">
                                <MyBtn
                                    title="О Банке" large
                                    onClick={() => navigate("/aboutBank")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='benefit-container'>
                        <div className="benefit-row">
                            <div className="benefit-no">01</div>
                            <div className="benefit-text">
                                Развитие бизнеса Банка строится на предоставлении клиентам широкого спектра
                                высокотехнологических банковских продуктов и услуг на уровне международных стандартов.
                            </div>
                        </div>

                        <div className="benefit-row">
                            <div className="benefit-no">02</div>
                            <div className="benefit-text">
                                Банк «Международная СберКасса» готов предоставить гарантию успешных платежей для
                                русскоязычных Клиентов, вне зависимости от политических границ.
                            </div>
                        </div>

                        <div className="benefit-row">
                            <div className="benefit-no">03</div>
                            <div className="benefit-text">
                                Широкий список Банков - Корреспондентов позволяет нам осуществлять переводы любой
                                сложности
                                в любую точку мира.
                            </div>
                        </div>

                        <div className="benefit-row">
                            <div className="benefit-no">04</div>
                            <div className="benefit-text">
                                Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки,
                                инвестировать
                                в недвижимость, а также преумножать свой капитал.
                            </div>
                        </div>
                    </div>

                    <div className="home-card-section">
                        <div className="cards-text">
                            <h1 className="section-title-center">
                                Наши эксклюзивные преимущества
                            </h1>
                            <div className="cards-info">
                                "Расширьте ваш финансовый опыт с нашим эксклюзивным пакетом преимуществ,
                                разработанным для обеспечения удобства и безопасности. Исследуйте мир возможностей и
                                спокойствия, выбирая нас."
                            </div>
                        </div>
                        <div className="cards-container">
                            <div className="card">
                                <div className="card-title">Международная
                                    ориентация
                                </div>
                                <div className="card-content">“Поддерживаем экономическое и социальное развитие
                                    резидентов славянских стран на международной арене, обеспечивая доступ к
                                    передовым банковским услугам.”
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-title">Широкий спектр
                                    услуг
                                </div>
                                <div className="card-content">"Предлагаем клиентам разнообразные банковские услуги
                                    высокого качества, включая услуги по финансированию, инвестированию, и
                                    обслуживанию счетов."
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-title">Инновационные технологии
                                </div>
                                <div className="card-content">"Используем передовые банковские технологии, чтобы
                                    обеспечить удобство и безопасность для наших клиентов."
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-title">Безопасность и Надежность
                                </div>
                                <div className="card-content">"Осуществляем банковские операции с высоким уровнем
                                    безопасности и надежности, защищая интересы наших клиентов."
                                </div>
                            </div>


                        </div>
                        <div className="cards-btn">
                            <MyBtn
                                title="Открыть Счет" large
                                onClick={() => navigate("/register")}
                            />
                        </div>

                    </div>

                    <div className="quote-container" >
                        <div className="quote-text">"Мы с уважением относимся к каждому резиденту <br/> нашей Земли."
                        </div>
                    </div>

                    <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                        <div className="title">
                            Успешно!</div>
                        <div className="modal-section">
                            Ваша заявка была отправлена.
                        </div>
                        <div className="modal-section">
                            <MyBtn medium title={"Ok"} onClick={() => {setIsPopupOpen(false)}}/>
                        </div>
                    </CustomModal>


                    <div className="home-contact-container">
                        <p className="home-contact-title">
                            Получите <b>консультацию</b> специалиста
                        </p>

                        <form className="contact-form"
                              onSubmit={handleSubmit}
                        >
                            <div className="input-block">
                                <div className="label">ФИО контактного лица</div>
                                <MyInput
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Виктория Давлатова"
                                />
                            </div>
                            <div className="input-block">
                                <div className="label">E-MAIL</div>
                                <MyInput
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="bub@mail.ru"
                                />
                            </div>
                            <div className="input-block">
                                <div className="label">Телефон</div>
                                <MyInput
                                    id="phone"
                                    name="phone"
                                    placeholder="Контактный номер телефона"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>

                            <MyBtn
                                title="Отправить"
                                large style={{width: '420px'}}
                                type="submit"
                            />
                        </form>
                    </div>
                </div>
            </section>
    );
};

export default Home;
