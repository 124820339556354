import React, {useState} from 'react';
import './RegistrationForm.css';
import MyInput from "../../../ui/MyInput/MyInput";
import MyDropdown from "../../../ui/CustomDropdown/MyDropdwon";
import {Formik} from "formik";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import {postRegistration} from "../../../../store/reducers/actions";
import {useAppDispatch} from "../../../../store/store";
import CustomModal from "../../../ui/customModal/customModal";

function RegistrationForm(props) {

    const accountTypes = [
        'Персональный аккаунт',
        'Бизнес аккаунт'
    ]
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)

    const dispatch = useAppDispatch()

    return (
        <>
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <span style={{fontSize: 12}}>**Пароль должен состоять хотя бы из 8 символов и
                        содержать <br/> минимум одну цифру, одну заглавную и одну строчную буквы.</span>

                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsErrorPopupOpen(false)
                    }}/>
                </div>
            </CustomModal>

            <Formik
                initialValues={{
                    email: '',
                    phone: '',
                    password: '',
                    accountType: ''
                }}
                onSubmit={(values, actions) => {
                    props.setLoginData(
                        {
                            ...props.dataForSend,
                            email: values.email,
                            password: values.password,
                        }
                    );

                    const type = values.accountType === "Персональный аккаунт" ? "personal" : "business";

                    dispatch(postRegistration({...values, type}))
                        .then(() => {
                            console.log('Registration successful!');
                            props.setCurrent(1);
                        })
                        .catch(error => {
                            console.error('Registration error:', error);
                            setIsErrorPopupOpen(true)
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>

                        <div className="sign_in_content">
                            <div className="title">
                                Добро Пожаловать!
                            </div>
                            <MyInput
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange}
                                required
                            />

                            <MyInput
                                required
                                id="phone"
                                name="phone"
                                placeholder="Номер телефона"
                                value={formikProps.values.phone}
                                onChange={formikProps.handleChange}
                            />

                            <MyInput id="password"
                                     required
                                     name="password"
                                     password
                                     placeholder="Пароль"
                                     value={formikProps.values.password}
                                     onChange={formikProps.handleChange}
                            />

                            <MyDropdown
                                customId="accountType"
                                required
                                items={accountTypes}
                                placeholder={"Тип аккаунта"}
                                id="accountType"
                                name="accountType"
                                value={formikProps.values.accountType}
                                onChange={formikProps.handleChange}
                            />


                            <div className="btn_block">
                                <MyBtn title={"Регистрация"} type={"submit"} style={{height: '50px', width: '200px'}}/>
                            </div>

                            <div className="auth-question">
                                Есть аккаунт СберКассы?
                                <a href="/login" className='auth-question-link'> Войти</a>
                            </div>

                        </div>

                    </form>
                )}
            </Formik>
        </>
    )
}

export default RegistrationForm